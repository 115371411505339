import React, { useState, useEffect } from 'react';
import { LiaCookieBiteSolid } from "react-icons/lia";
import { IoSettingsOutline } from "react-icons/io5";

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {    
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'false');
    setShowBanner(false);
  };

  const handleSettings = () => {
    setShowSettings(!showSettings);
  }

  if (!showBanner) return null;

  return (
    <>
    <div className='ConsentContainer' >
      <LiaCookieBiteSolid className='CookieIcon' /> 
      <p style={{ margin: '0 0 10px', fontStyle: "italic"}}>Mēs izmantojam sīkfailus, lai nodrošinātu vietnes darbību un uzlabotu Jūsu lietošanas pieredzi. Izvēloties izmantot šo vietni, jūs piekrītat tikai tiem sīkfailiem, kas nepieciešami tās pareizai darbībai un analītikai.

Jūs varat piekrist vai atteikties no sīkfailu izmantošanas, izvēloties kādu no iespējām.</p>
    <div className='ButtonsContainer'>
      <button onClick={handleAccept} className='logIn'>Piekrītu</button>
      <IoSettingsOutline onClick={handleSettings} className='thirdRowIcon' style={{marginLeft: '10px', cursor: 'pointer', marginTop: "8px "}}/>       
    </div>
    {showSettings && (
        <>
        <p style={{ margin: '10px 0 0', fontStyle: "italic"}}>Mēs izmantojam sīkfailus, tostarp Google Tag Manager, lai uzlabotu jūsu lietošanas pieredzi un analizētu mūsu vietnes darbību. 

Ja nepiekrītat sīkfailu izmantošanai, spiediet "Noraidīt"</p>
<div className='ButtonsContainer'>
        <button onClick={handleDecline} className='logIn'>Noraidīt</button>
        </div>
        </>
      )}     
    </div>
    <div className='Cover-container'></div>
    </>  
    
  );
};

export default CookieConsent;
