import React, { useState, useEffect } from "react";
import { GetMenu } from "..//ProdHandleLogic.js";
import { Link } from "react-router-dom";
import './Drop.css'
import { IoReorderThreeOutline } from "react-icons/io5";
import { IoIosArrowForward, IoIosArrowDown  } from "react-icons/io";
import { GrClose } from "react-icons/gr";
import { set } from "date-fns";

export function AllCategoriesDrop() {
    const [menuItems, setMenuItems] = useState([]);
    const [selectedMenu1, setSelectedMenu1] = useState(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        GetMenu()
            .then((data) => {
                const result = data.reduce((acc, current) => {
                    const { MENU_1, MENU_2, MENU_3 } = current;

                    if (!acc[MENU_1]) {
                        acc[MENU_1] = {};
                    }

                    if (!acc[MENU_1][MENU_2]) {
                        acc[MENU_1][MENU_2] = new Set();
                    }

                    acc[MENU_1][MENU_2].add(MENU_3);

                    return acc;
                }, {});

                for (const menu1 in result) {
                    for (const menu2 in result[menu1]) {
                        result[menu1][menu2] = Array.from(result[menu1][menu2]);
                    }
                }

                setMenuItems(result);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const handleClick = (e) => {
        e.preventDefault();
        setShow(!show);
        setSelectedMenu1(null);
    };

    const handleMenu1Click = (menu1) => {        
        if (selectedMenu1 === menu1) {
            setSelectedMenu1(null);
            return;
        }
        setSelectedMenu1(menu1);        
    }
    
    return (
        <>
        {show ? <div className="Cover-container"> </div> : null}
        <div className="main-container">
            <div className="virsraksts" onClick={handleClick}>
                <IoReorderThreeOutline
                    className="thirdRowIcon"
                />
                <div>Preču kategorijas</div>
            </div>
            
             <div className={`mobileContets ${show ? 'active' : ''}`}>
             
                        <div className='virsraksts-cartridge'>
                <h2>Preču kategorijas</h2>
                <GrClose onClick={handleClick}  className='icon'>Aizvērt</GrClose>
                </div>
                {Object.keys(menuItems).sort((a, b) => a.localeCompare(b)).map((menu1, index) => (
                    <div className="mobileCategoryContainer">
                        <div key={index} className="MobileCategryRow"
                        
                        >
                            <img src={`/catImages/${menu1}.jpg`} alt={menu1} className="smallAllCategoriesImage"
                            onClick={(e) => {
                                e.preventDefault();
                                if (menu1 === "Printeru kārtridži") {
                                    window.location.href = `/cartridges`;
                                } else if (menu1 === "Kopējošās lentas (ribbon)") {
                                    window.location.href = `/thermalTransfer`;
                                } else  {
                                    window.location.href = `/categories/${menu1.replace(/\s/g, '-')}`;
                                }
                            }} />
                            <div style={{width: "85%"}}
                            onClick={(e) => {
                                e.preventDefault();
                                if (menu1 === "Printeru kārtridži") {
                                    window.location.href = `/cartridges`;
                                } else if (menu1 === "Kopējošās lentas (ribbon)") {
                                    window.location.href = `/thermalTransfer`;
                                } else  {
                                    window.location.href = `/categories/${menu1.replace(/\s/g, '-')}`;
                                }
                            }}>
                                {menu1}                                
                            </div>
                            {selectedMenu1 === menu1 ? <IoIosArrowDown className="plusIcon" onClick={(e) => {
                            e.preventDefault();
                            handleMenu1Click(menu1);
                        }}/> :<IoIosArrowForward className="plusIcon" onClick={(e) => {
                            e.preventDefault();
                            handleMenu1Click(menu1);
                        }}/>}
                        </div>
                        {selectedMenu1 === menu1 && (
                            Object.keys(menuItems[menu1]).sort((a, b) => a.localeCompare(b)).map(menu2 => (
                                <div key={menu2} className="MobileSubmenu"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = `/categories/${menu1.replace(/\s/g, '-')}/${menu2.replace(/\s/g, '-')}`;
                                    }}>
                                    <img src={`/catImages/${menu2}.jpg`} alt={menu2} className="smallAllCategoriesImage" />
                                    {menu2}
                                </div>
                            ))
                        )}
                    </div>
                ))}

                {/* {menuItems.map((item) => (
                    <Link onClick={(e) => {e.preventDefault(); setShow(!show);  window.location.href = item.MENU_1 === "Printeru kārtridži" ?`/cartridges` : item.MENU_1 === "Kopējošās lentas (ribbon)" ?`/thermalTransfer` :`/categories/${item.MENU_1.replace(/\s/g, '-')}` }}>
                        <div key={item}>
                            {item.MENU_1}
                        </div>
                    </Link>
                ))} */}
            </div>
            
        </div>
        </>
    );
}
