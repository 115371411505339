
import { Route, Routes } from "react-router-dom";
import HomePage from './Pages/main';
import Categories from './components/ProductDisplay/categories';
import NotFoundPage from './Pages/notfound';
import Header from './components/header/header';
import { Container, Row, Col } from 'react-bootstrap/';
import Cart from './Pages/Cart/cart';
import CartMain from './Pages/Cart/cartMain';
import UserOrders from './components/Users/UserOrders';
import UserProfile from './components/Users/UserProfile';
import Checkout from "./Pages/Checkout/checkout not working";
import Cartridges from "./Pages/Cartridges/cartridges.js";
import Ribbon from "./Pages/Ribbon/ribbon.js"
import ProductSlider from './components/Reklama/Carousel/Carousel.js';
import './App.css';
import PasswordReset from "./components/Users/ResetPassword";
import FullSearchResults from "./components/Search/SearchResults";
import CartridgeSearchResults from "./components/Search/cartridgeSearchResults";
import Favorites from "./components/Favorites/Favorites";
import SpecialOffers from "./components/SpecialOffers/SpecialOffers";
import Panel from "./components/AdminPanel/Panel";
import LeftImage from "./components/Reklama/Sides/LeftSide";
import RightImage from "./components/Reklama/Sides/RightSide";
import Footer from "./components/Footer/footer";
import Terms from "./Pages/terms";
import Privacy from "./Pages/privacy";
import Utilizacija from "./Pages/utilizacija";
import Sale from "./Pages/Sale";
import CookieConsent from "./components/Cookiekonsent/Cookiekonsent";
import { useLocation } from "react-router-dom";





function App() {
  const location = useLocation();
  return (
    <>
      <Container fluid>
        <CookieConsent />
        <Row>
          <Header />
        </Row>
        <Row>
          {location.pathname === "/panel" && (
            <Row>
              <Panel />
            </Row>
          )}
          {location.pathname === "/" && (
            <Row>              
              <HomePage />              
            </Row>
          )}
          {location.pathname === "/cartridges" && (
            <Row>
              <Cartridges />
            </Row>
          )}
          {location.pathname === "/cartridgeSearchResults" && (
            <Row>
              <CartridgeSearchResults />
            </Row>
          )}
          {location.pathname === "/thermalTransfer" && (
            <Row>
              <Ribbon />
            </Row>
          )}
        </Row>
        {location.pathname !== "/panel" && location.pathname !== "/" && location.pathname !== "/thermalTransfer" && location.pathname !== "/cartridges" && location.pathname !== "/cartridgeSearchResults" && (
          <Row>
            <Col className="custom-col-1">
              <LeftImage />
            </Col>
            <Col className="custom-col-8">
              <Routes>
                <Route path="/cart" element={<CartMain />} />
                <Route path="/sale" element={<Sale />} />
                <Route path="/utilizacija" element={<Utilizacija />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/search-results" element={<FullSearchResults />} />                
                <Route path="/user-profile" element={<UserProfile />} />
                <Route path="/order-history" element={<UserOrders />} />
                <Route path="/reset-password" element={<PasswordReset />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/favorites" element={<Favorites />} />
                <Route path="/special-offers" element={<SpecialOffers />} />                        
                <Route path="/categories">
                  <Route index element={<Categories />} />
                  <Route path=":menu1">
                    <Route index element={<Categories />} />
                    <Route path=":menu2">
                      <Route index element={<Categories />} />
                      <Route path="categories/:menu1/:menu2/1/:product" element={<Categories />} />
                      <Route path=":menu3">
                        <Route index element={<Categories />} />
                        <Route path=":product" element={<Categories />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Col>
            {location.pathname !== "/cart" && location.pathname !== "/checkout" && (
              <Col className="custom-col-3">                
                <Cart />
                <RightImage />
              </Col>
            )}
          </Row>
        )}
        <Row>
        {location.pathname !== "/" && location.pathname !== "/checkout" && location.pathname !== "/cart" && location.pathname !== "/panel" &&<ProductSlider />}
          <Footer />
        </Row>
      </Container>
    </>
  );
}

export default App;