

import React, { useState, useEffect } from 'react';
import { getCart, updateQuantity } from '../../context/cartFunctions';
import './cart.css';
import { Link } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { RiDeleteBinLine } from "react-icons/ri";
import { onAuthStateChanged } from 'firebase/auth';
import useFirebase from "../../firebase/usefirebase";
import { RiQuestionLine } from "react-icons/ri";
import {loadKlixScript} from '..//..//context/clikx';
import { da, is } from 'date-fns/locale';


const CART_KEY = 'cart';

const Cart = () => {
  const [cartItems, setCartItems] = useState(false);
  const [dati, setDati] = useState(false);
  const [total, setTotal] = useState(0);
  const [weight, setWeight] = useState(false);
  const [maxParcelShopWeight, setMaxParcelShopWeight] = useState(0);
  const [authUser, setAuthUser] = useState(null);
  const [email, setEmail] = useState(null);
  const [freeDelivery, setFreeDelivery] = useState(false);
  const [smallOrderFee, setSmallOrderFee] = useState(0);
  const [smallOrderFeeLimit, setSmallOrderFeeLimit] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [authLoading, setAuthLoading] = useState(true);
  const [showKlix, setShowKlix] = useState(true);
  const [onlyCartridgesInCart, setOnlyCartridgesInCart] = useState(false);
  const { auth, user } = useFirebase();
  const API_SERVER = process.env.REACT_APP_API_SERVER;

  let totalValue = 0;



  useEffect(() => {    
    if (auth) {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setAuthUser(user);
          setEmail(user.email);
          setAuthLoading(false);
        } else {
          setAuthUser(null);
          setAuthLoading(false);
        }
      });
      return () => {
        unsubscribe();
      };
    } else {
      setAuthLoading(false); 
    }
    
  }, [API_SERVER, auth]);

  
  useEffect(() => {
    if (authLoading) return; 
    const handleChange = () => {
      const saturs = getCart();
      setShowKlix(true);
      if (saturs.length > 0) {
        const skus = saturs.map(item => item.sku);
        const skuString = skus.join(',');
        fetch(`${API_SERVER}/sku?skus=${skuString}&email=${email}`)
          .then((response) => {            
                    if (!response.ok) {
                        throw new Error("Failed to fetch user data");
                    }
                    return response.json();
                })
                .then((data) => {
                    setDati(data);                    
                    setCartItems(getCart());
                    fetch(`${API_SERVER}/freeDelivery`)
                    .then((response) => {
                      if (!response.ok) {
                          throw new Error("Failed to fetch user data");
                      }
                      return response.json();
                  })
                  .then((data) => {
                      setFreeDelivery(data.free);
                      setSmallOrderFee(parseFloat(data.smallOrderFee));
                      setSmallOrderFeeLimit(parseFloat(data.smallOrderFeeLimit));
                      setMaxParcelShopWeight(parseFloat(data.MaxSvarsPakomats));
                  })
                })
                .catch((error) => console.error(error));
      } else if (saturs.length === 0) {
        setCartItems(getCart());
      } 
    };

    handleChange();
    window.addEventListener('storage', handleChange);
    
    return () => {
      window.removeEventListener('storage', handleChange);
    };
  }, [authUser, authLoading]);
  
  useEffect(() => {
    const calculateTotal = async () => {
      let totalValue = 0;
      let totalWeight = 0;
      if (dati && cartItems) {
        cartItems.forEach((item) => {
          const product = dati.find((p) => p.SKU === item.sku);
          totalValue += item.quantity * product.PRICE;
          totalWeight += item.quantity * product.WEIGHT;

          if (Number(product.QTY) === 0 || product.QTY === null) {
            setShowKlix(false);
          }

        });

        setTotal(parseFloat(totalValue));
        if (totalValue < 79.99) {
          setShowKlix(false);
        }
        setWeight(parseFloat(totalWeight));

        const allContainCartridge = dati.every(row => row.MENU_1.includes('ārtridži'));

        if (allContainCartridge) {
          setOnlyCartridgesInCart(true);
        } else {
          setOnlyCartridgesInCart(false);
        }

        setIsLoading(false);
      }
    };
    calculateTotal();
    loadKlixScript();
  }, [dati, cartItems]);


  const handleQuantityChange = (sku, newQuantity) => {
    const updatedCart = cartItems.map((item) =>
      item.sku === sku ? { ...item, quantity: newQuantity } : item
    );
    updateQuantity(sku, newQuantity);
    setCartItems(updatedCart);
  };


const saveCart = (cart) => {
  localStorage.setItem(CART_KEY, JSON.stringify(cart));
  window.dispatchEvent(new Event('storage'));
};


const removeFromCart = (sku) => {
  const cart = getCart();
  const updatedCart = cart.filter((item) => item.sku !== sku);
  saveCart(updatedCart);
};
 
  return (
    <div className="cart-container">
      {isLoading? null :!cartItems || cartItems.length === 0 || !freeDelivery ? null : (
        <>
          <div className="cart-items-container">          
            {weight < maxParcelShopWeight || onlyCartridgesInCart ? (
            total < freeDelivery && !onlyCartridgesInCart ? (
              <>
                <ProgressBar now={(total / freeDelivery) * 100} className="custom-progress-bar" />
                <p style={{ fontSize: '13px' }}>Līdz bezmaksas piegādei: €{(freeDelivery - total).toFixed(2)} </p>
              </>
            ) : (
              <div className='Free'>
              <img src='/images/freeDelivery.gif' alt='Bezmaksas piegāde | OfficeShop' className='freeDeliveryImage'/>
              </div>
            )
            ) : (
              <div className='Free'>
              <img src='/images/kurjeraPiegade.gif' alt='Kurjera piegāde | OfficeShop' className='freeDeliveryImage'/>
              </div>
            )}
            {cartItems.map((item) => {
              const product = dati.find((p) => p.SKU === item.sku);
              return (
                <div className="cart-items" key={item.sku}>
                  <div className="cart-item">
                    <div className="product-image-container">
                      <img src={
                        product.MENU_2 === "Officeshop ražotie kārtridži"?  '/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg':
                        product.MENU_1 === "Kopējošās lentas (ribbon)" ? '/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg':
                        product.PICTURE ? product.PICTURE.startsWith('http') ? product.PICTURE : `/prodImages/Small/${product.SKU} (Small)/${product.SKU} (Small).${product.PICTURE}`:
                        `/prodImages/Small/logo (Small)/logo (Small).png`} alt={product.NAME} className="image" />
                    </div>
                    <p className="name">
                      <Link to={
                        product.MENU_2 === 'N/A' ? `/categories/${product.SKU}` :
                          product.MENU_3 === 'N/A' ? `/categories/${encodeURIComponent(product.MENU_1).replace(/%20/g, '-')}/${encodeURIComponent(product.MENU_2).replace(/%20/g, '-')}/${product.SKU}` :
                            `/categories/${encodeURIComponent(product.MENU_1).replace(/%20/g, '-')}/${encodeURIComponent(product.MENU_2).replace(/%20/g, '-')}/${encodeURIComponent(product.MENU_3).replace(/%20/g, '-')}/${product.SKU}`
                      }>
                        {product.NAME}
                      </Link>
                    </p>
                    <p className="cena">€{product.PRICE}</p>
                    <input
                      className='quantity-input'
                      type="number"
                      value={item.quantity}
                      onChange={(e) =>
                        handleQuantityChange(item.sku, parseInt(e.target.value, 10))
                      }
                    min={product.MINORDER? product.MINORDER : 1}
                  />
                  <p className="cena">€{(product.PRICE * item.quantity).toFixed(2)}</p>
                  <RiDeleteBinLine
                    style={{
                      color: "black",
                    }}
                    className="remove-button" 
                    onClick={() => removeFromCart(product.SKU)}
                  />
                </div>
              </div>
              );
            })}
          </div>
          <div className="total-container">
          {total < smallOrderFeeLimit ? (
              <>
              <div className='smallOrderNotification'><RiQuestionLine title={`Pasūtījumiem līdz € ${smallOrderFeeLimit} tiek piemērota apkalpošanas maksa € ${smallOrderFee}`}/>Maza pasūtījuma apkalpošanas maksa:   € {smallOrderFee}</div>
              <p>Kopā apmaksai:   € {(total + smallOrderFee).toFixed(2)}</p>
              </>
            ) : (            
            <p>Kopā apmaksai:   € {total.toFixed(2)}</p>
            )}
                 
            <button className="btn-small-red-center" onClick={(e) => {e.preventDefault(); window.location.href = !showKlix ? '/checkout' : '/checkout?data=full'}}>Pasūtīt</button>            
            {showKlix && total >79.99 && (              
            <div className='ButtonAndWidget'>    
            <klix-pay-later amount={(total.toFixed(0))*100} brand_id="dd14917f-4fa5-47e7-a39e-a7a5764e712d" 
                language="lv" theme="light" view="product" category="SPORTS_GOODS" type="micro">
              </klix-pay-later>
              
            </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
