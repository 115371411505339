import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { getCart } from '../../context/cartFunctions';
import useNotification  from "..//../components/Notifications/notificationHandler"
import useFirebase from "../../firebase/usefirebase";
import { Helmet } from 'react-helmet';
import './checkout.css';

const Checkout = () => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [customerType, setCustomerType] = useState("private");
    const [surname, setSurname] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [vatNumber, setVatNumber] = useState("");
    const [phone, setPhone] = useState("");
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [streetNo, setStreetNo] = useState("");
    const [flatNo, setFlatNo] = useState(null);
    const [postCode, setPostCode] = useState("");
    const [regNumber, setRegNumber] = useState("");
    const [legalAddress, setLegalAddress] = useState("");
    const [isCompany, setIsCompany] = useState(false);
    const [OrderPlaced, setOrderPlaced] = useState(false);
    const [dpdData, setDpdData] = useState([]);
    const [courier, setCourier] = useState(null);
    const [pakomats, setPakomats] = useState([]);
    const [pakomataNosaukums, setPakomataNosaukums] = useState(null);
    const [userSearch, setUserSearch] = useState([]);
    const [modelIsActive, setModelIsActive] = useState(false);
    const [allsubmited, setAllSubmited] = useState(false);
    const [showPage, setShowPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [weight, setWeight] = useState(0);
    const [outOfStock, setOutOfStock] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [dpdprice, setDpdprice] = useState(0);
    const [omnivaprice, setOmnivaprice] = useState(0);
    const [doorToDoorPrice, setDoorToDoorPrice] = useState(0);
    const [deliveryPrice, setDeliveryPrice] = useState(0);
    const [smallOrderFee, setSmallOrderFee] = useState(0);
    const [userCity, setUserCity] = useState("");
    const [userStreet, setUserStreet] = useState("");
    const [userPostCode, setUserPostCode] = useState("");
    const [userStreetNo, setUserStreetNo] = useState("");
    const [userFlatNo, setUserFlatNo] = useState("");
    const [termsAccept, setTermsAccept] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [authLoading, setAuthLoading] = useState(true);    
    const [paymentOption, setPaymentOption] = useState(null);
    const [pageType, setPageType] = useState(null);
    const [cartToShow, setCartToShow] = useState([]); 
    const { auth, user } = useFirebase();
    const location = useLocation();
    const showNotification = useNotification();
    const API_SERVER = process.env.REACT_APP_API_SERVER;
    let id = null;
    

    useEffect(() => {
        if (auth){
            const unsubscribe = onAuthStateChanged(auth, async (user) => {
                if (user) {
                    setIsAuthorized(user);
                    setEmail(user.email);

                    const cart = getCart();
                    const serializedCart = encodeURIComponent(JSON.stringify(cart));
                    setCartItems(cart);
                    const response = await fetch(`${API_SERVER}/checkout?email=${user.email}&cart=${serializedCart}`);
                    if (!response.ok) {
                        throw new Error("Failed to fetch user data");
                    }
                    const res = await response.json();
                    const data = res.user[0];
                    const delivery = res.delivery;
                    const kopsumma = res.total;
                    const weight = res.weight;
                    setTotal(kopsumma);
                    setWeight(weight);
                    setOutOfStock(res.outOfStock);
                    setSmallOrderFee(res.SmallOrderFee);
                    
                    if (delivery.length > 0) {
                        setDpdprice(delivery[0].DPD);
                        setOmnivaprice(delivery[0].Omniva);
                        setDoorToDoorPrice(delivery[0].DPDk);
                    }
                    setName(data.Name);
                    setSurname(data.Surname);
                    setPostCode(data.PostCode);
                    setCity(data.City);
                    setStreet(data.Street);
                    setStreetNo(data.StreetNo);
                    data.FlatNo && setFlatNo(data.FlatNo);
                    setPhone(data.Phone);
                    if (data.Type === "company") {
                        setVatNumber(data.VAT);
                        setCompanyName(data.CompanyName);
                        setIsCompany(true);
                        setLegalAddress(data.LegalAddress);
                        setRegNumber(data.RegNumber);
                    }
                    setIsLoading(false);
                    setAuthLoading(false); 
                } else {
                    const cart = getCart();
                    const serializedCart = encodeURIComponent(JSON.stringify(cart));
                    setCartItems(cart);
                    const response = await fetch(`${API_SERVER}/checkout?cart=${serializedCart}`);
                    if (!response.ok) {
                        throw new Error("Failed to fetch user data");
                    }
                    const res = await response.json();
                    const delivery = res.delivery;
                    const kopsumma = res.total;
                    const weight = res.weight;
                    setTotal(kopsumma);
                    setWeight(weight);
                    setOutOfStock(res.outOfStock);
                    setSmallOrderFee(res.SmallOrderFee);
                    if (delivery.length > 0) {
                        setDpdprice(delivery[0].DPD);
                        setOmnivaprice(delivery[0].Omniva);
                        setDoorToDoorPrice(delivery[0].DPDk);
                    }
                    setIsLoading(false);
                    setAuthLoading(false); 
                }
            });
            return () => unsubscribe();
        } else {
            setAuthLoading(false); 
        }
    }, [API_SERVER, auth]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const data = queryParams.get('data');
        if (data) {
          setPageType('options');
        } else {
          setPageType(null);
          setPaymentOption("Invoice");
        }
      }, [location]);

    useEffect(() => {
        if (showPage === 2) {
            const skus = cartItems.map(item => item.sku);
            const skuString = skus.join(',');
            fetch(`${API_SERVER}/sku?skus=${skuString}&email=${email}`)
              .then((response) => {            
                        if (!response.ok) {
                            throw new Error("Failed to fetch user data");
                        }
                        return response.json();
                    })
                    .then((data) => {
                        setCartToShow(data);                         
                    })
                    .catch((error) => console.error(error));
                }
    }, [showPage]);

    const handleCheckout = async (e) => {
        e.preventDefault();
        
        if (paymentOption === "Klix") {
            setIsLoading(true);
            try {
                const response = await fetch(`${API_SERVER}/klix`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        products: cartItems,
                        email: email
                    }),
                });
                if (!response.ok) {
                    throw new Error("Failed to fetch user data");
                }
                const data = await response.json();                
                id = data.id;
                window.location.href = data.checkout_url;
            }
            catch (error) {
                console.error("Failed to create new order:", error);
            }
        } else if (paymentOption === "Invoice") {
            setIsLoading(true);
            setTimeout(() => {
                setOrderPlaced(true);
            }, 2500);
        }
        
        const cityToUse = city === '' ? userCity : city;
        const streetToUse = street === '' ? userStreet : street;
        const streetNoToUse = streetNo === '' ? userStreetNo : streetNo;
        const postCodeToUse = postCode === '' ? userPostCode : postCode;
        const flatNoToUse = flatNo === '' ? userFlatNo : flatNo;
        
        if (allsubmited) {
            try {
                const response = await fetch(`${API_SERVER}/placeOrder`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        Name: name,
                        Surname: surname,
                        Email: email,
                        Phone: phone,
                        CompanyName: companyName,
                        VAT: vatNumber,
                        City: cityToUse,
                        Street: streetToUse,
                        StreetNo: streetNoToUse,
                        FlatNo: flatNoToUse,
                        PostCode: postCodeToUse,
                        RegNumber: regNumber,
                        LegalAddress: legalAddress,
                        isCompany: isCompany,
                        OrderDate: new Date().toISOString(),
                        pakomats: pakomats,
                        kurjers: courier,
                        pakomataNosaukums: pakomataNosaukums,
                        Total: total,
                        guest: isAuthorized,
                        Cart: cartItems,
                        deliveryPrice: deliveryPrice,
                        paymentOption: paymentOption,
                        id: id,        
                    }),
                });
                const data = await response.json();
            }
            catch (error) {
                console.error("Failed to create new order:", error);
            }
            finally {
                if (paymentOption === "Invoice") {
                    localStorage.removeItem('cart');
                    localStorage.setItem('cart', JSON.stringify([]));
                    window.dispatchEvent(new Event('storage'));
                }
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'purchase',
                    conversionId: '16721545068',
                    conversionLabel: 'SExvCOmsg94ZEOyOuqU-'                          
                });
            }
        }
    };

    useEffect(() => {
        if (courier === "DPD" || courier === "Omniva") {
            fetch(`${API_SERVER}/pakomati?kurjers=${courier}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })

                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch courier data");
                    }
                    return response.json();
                })
                .then((data) => {
                    const sortedData = data.sort((a, b) => a.Nosaukums.localeCompare(b.Nosaukums));
                    setDpdData(sortedData);
                })
                .catch((error) => console.error(error));
        }
    }, [courier]);

    useEffect(() => {
        if (authLoading) return; 
        if (courier === "DPD" || courier === "Omniva") {
            const nosaukums = dpdData.find((nosaukums) => nosaukums.Id === pakomats)?.Nosaukums;
            setPakomataNosaukums(nosaukums);
        }
    }, [pakomats]);
    
    useEffect(() => {
        if (
            (
              name.length > 2 &&
              surname.length > 2 &&
              termsAccept &&
              email.includes('@') &&
              email.includes('.') &&
              /^\d{0,8}$/.test(phone) &&
              phone.length === 8 &&
              paymentOption !== null
            ) || ( 
              isAuthorized &&
              termsAccept &&
              paymentOption !== null
            )
          ){
            if (customerType === "company") {
                if (companyName.length>2 &&  regNumber.length === 11 && legalAddress.length>6) {
                    if (courier === 'door-to-door' &&
                        ((city.length<3 || street.length<3 || streetNo === "" || postCode.length<4) &&
                            (userCity === "" || userStreet === "" || userStreetNo === "" || userPostCode === ""))) {
                        setAllSubmited(false);
                    } else if ((courier === 'DPD' || courier === 'Omniva') && pakomats.length === 0) {
                        setAllSubmited(false);
                        return;
                    } else if(!courier){
                        setAllSubmited(false);
                        return;
                    } else {                        
                        setAllSubmited(true);
                    }
                } else {
                    setAllSubmited(false);
                }

            } else if (customerType === "private") {
                if (courier === 'door-to-door' &&
                    ((city.length<3 || street.length<3 || streetNo === "" || postCode.length<4) &&
                        (userCity === "" || userStreet === "" || userStreetNo === "" || userPostCode === ""))) {
                    setAllSubmited(false);
                } else if ((courier === 'DPD' || courier === 'Omniva') && pakomats.length === 0) {
                    setAllSubmited(false);
                    return;
                } else if(!courier){
                    setAllSubmited(false);
                    return;
                }
                else {
                    setAllSubmited(true);
                }
            }
        } else {
            setAllSubmited(false);
        }
        return;
    }, [name, surname, paymentOption, termsAccept, email, phone, companyName, regNumber, legalAddress, city, street, streetNo, postCode, pakomats, courier, customerType, userCity, userStreet, userStreetNo, userPostCode]);

    const handleTerms = () => {
        setTermsAccept(!termsAccept);
    };

    const handleSearch = (e) => {
        const searchValue = e.target.value;
        const filteredPakomati = dpdData.filter((skapis) =>
            skapis.Nosaukums.toLowerCase().includes(searchValue.toLowerCase())
        );
        setUserSearch(filteredPakomati);
    };
    const modelActive = () => {
        setModelIsActive(!modelIsActive);
    };
    
        
    return (
        <>
        <Helmet>
        <title>Veikt pasūtījumu - Officeshop</title>
        <meta name="description" content="Officeshop.lv - ātra piegāde" />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Veikt pasūtījumu - Officeshop" />
        <meta property="og:description" content="Officeshop.lv - ātra piegāde" />
        <meta property="og:url" content="https://www.officeshop.lv/checkout" />
        <meta property="og:image" content="https://www.officeshop.lv/prodImages/logo.png" />
        <meta property="og:type" content="website" />
      </Helmet>
        <div className="checkout-container">
            {OrderPlaced ? (
                <>
                    <div className="Cover-container">
                        <div className="logs">
                            <h2>Jūsu pasūtījums ir saņemts!</h2>
                            <p>Drīzumā uz Jūsu norādīto e-pasta adresi nosūtīsim priekšapmaksas rēķinu.</p>
                            <button className='logIn' onClick={(e) => { e.preventDefault(); window.location.href = '/categories'; }} >Ok</button>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
        <div className="checkout-container">
            {isLoading ?
                <div className='loading-container'>
                    {!OrderPlaced && <img src='/images/loading.gif' alt='loading' />}
                    {allsubmited && !OrderPlaced ? <div className='loading-text' style={{textAlign: 'center'}}>Apstrādājam Jūsu pasūtījumu...</div> : null}
                </div> : 
                isAuthorized && showPage === 1? (
                    <div>
                        <h2 className="checkout-title">Pasūtītāja dati</h2>
                        <p className='chechout-addres-notification'>Ja nepieciešams mainīt pasūtītāja datus, to vari izdarīt savā profilā!</p>
                        {isCompany ? (
                            <>
                                <div className="field-group">
                                    <div>
                                        <p>Uzņēmuma nosaukums:</p>
                                        <span>{companyName}</span>
                                    </div>
                                </div>
                                <div className="field-group">
                                    <div>
                                        <p>Uzņēmuma reģistrācijas Nr.:</p>
                                        <span>{regNumber}</span>
                                    </div>
                                </div>
                                <div className="field-group">
                                    <div>
                                        <p>PVN reģistrācijas Nr.:</p>
                                        <span>{vatNumber}</span>
                                    </div>
                                </div>
                                <div className="field-group">
                                    <div>
                                        <p>Juridiskā adrese:</p>
                                        <span>{legalAddress}</span>
                                    </div>
                                </div>
                            </>
                        ) : null}
                        <div className="field-group">
                            <div>
                                <p>Vārds:</p>
                                <span>{name}</span>
                            </div>
                        </div>
                        <div className="field-group">
                            <div>
                                <p>Uzvārds:</p>
                                <span>{surname}</span>
                            </div>
                        </div>
                        <div className="field-group">
                            <div>
                                <p>E-pasts:</p>
                                <span>{email}</span>
                            </div>
                        </div>
                        <div className="field-group">
                            <div >
                                <p>Kontakttālrunis:</p>
                                <span>{phone}</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    showPage === 1 && (
                    <div>
                        <form>
                            <h1 className="checkout-title">Iegādāties kā viesim</h1>
                            <div className="field-group">
                                <div className="input-msg2">
                                    <input type="text" placeholder="Vārds" value={name} onChange={(e) => setName(e.target.value)} required />
                                </div>
                                <div className="input-msg2">
                                    <input type="text" placeholder="Uzvārds" value={surname} onChange={(e) => setSurname(e.target.value)} required />
                                </div>
                                <div className="input-msg2">
                                    <input type="text" placeholder="E-pasts" required value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="input-msg2">
                                    <input
                                        type="text"
                                        value={phone}
                                        placeholder="Kontakttālrunis"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d{0,8}$/.test(value)) {
                                                setPhone(value);
                                            }
                                        }}
                                        required
                                        pattern="\d{8}"
                                        maxLength="8"
                                        minLength="8"
                                        title="Kontakttālrunim jābūt precīzi 8 ciparu garam."
                                    />
                                </div>
                            </div>

                            <div className="radio-select">
                                <label>
                                    <input
                                        type="radio"
                                        value="private"
                                        checked={customerType === "private"}
                                        onChange={() => { setCustomerType("private"); setIsCompany(false); }}
                                    />
                                    <span className="radio-custom"></span>
                                    Privātpersona
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="company"
                                        checked={customerType === "company"}
                                        onChange={() => { setCustomerType("company"); setIsCompany(true); }}
                                    />
                                    <span className="radio-custom"></span>
                                    Juridiska persona
                                </label>
                            </div>
                            {customerType === "company" && (
                                <div>
                                    <h2 className="checkout-title">Uzņēmuma Rekvizīti</h2>
                                    <div className="field-group">
                                        <div className="input-msg2">
                                            <input type="text" placeholder="Uzņēmuma nosaukums" required value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="field-group">
                                        <div className="input-msg2">
                                            <input 
                                            type="text" 
                                            placeholder="Uzņēmuma reģistrācijas Nr." 
                                            value={regNumber} 
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d{0,11}$/.test(value)) {
                                                    setRegNumber(value);
                                                }
                                            }}
                                            required
                                            pattern="\d{11}"
                                            maxLength="11"
                                            minLength="11"
                                            title="Reģistrācijas Nr. jābūt precīzi 11 ciparu garam."
                                            
                                            />
                                        </div>
                                    </div>
                                    <div className="field-group">
                                        <div className="input-msg2">
                                            <input type="text" placeholder="PVN reģistrācijas Nr." value={vatNumber} onChange={(e) => setVatNumber(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="field-group">
                                        <div className="input-msg2">
                                            <input type="text" placeholder="Juridiskā adrese" required value={legalAddress} onChange={(e) => setLegalAddress(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                    )

                )
            }
            {isLoading ? null : showPage === 1 && ( 
            <>
                        {outOfStock.length > 0 ?
                        <>                             
                                <div className="seperator">                                    
                                </div>
                                <h2 className="checkout-title">Jūsu pasūtījumā ir {outOfStock.length === 1 ? 'prece, kura' : 'sekojošas preces, kuras'} pašreiz nav noliktavā.</h2>
                                <p className='chechout-addres-notification'>Piegādes laiku precizēsim pēc pasūtījuma saņemšanas</p>
                                {outOfStock.map((product) => (
                                <div className="cart-items" key={product.SKU}>
                                    <div className="cart-item">
                                        <div className="product-image-container">
                                            <img src={
                                                product.MENU_2 === "Officeshop ražotie kārtridži" ? '/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg' :
                                                    product.MENU_1 === "Kopējošās lentas (ribbon)" ? '/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg' :
                                                        product.PICTURE ? product.PICTURE.startsWith('http') ? product.PICTURE : `/prodImages/Small/${product.SKU} (Small)/${product.SKU} (Small).${product.PICTURE}` :
                                                            `/prodImages/Small/logo (Small)/logo (Small).png`} alt={product.NAME} className="image" />
                                        </div>
                                        <p className="name">
                                            <Link to={
                                                product.MENU_2 === 'N/A' ? `/categories/${product.SKU}` :
                                                    product.MENU_3 === 'N/A' ? `/categories/${encodeURIComponent(product.MENU_1).replace(/%20/g, '-')}/${encodeURIComponent(product.MENU_2).replace(/%20/g, '-')}/${product.SKU}` :
                                                        `/categories/${encodeURIComponent(product.MENU_1).replace(/%20/g, '-')}/${encodeURIComponent(product.MENU_2).replace(/%20/g, '-')}/${encodeURIComponent(product.MENU_3).replace(/%20/g, '-')}/${product.SKU}`
                                            }>
                                                {product.NAME}
                                            </Link>
                                        </p>
                                        <p className="cena">€{product.PRICE}</p>
                                    </div>
                                </div>))}
                            </>
                        : null}
            <div className="seperator"></div>
            {dpdprice === null && omnivaprice === null  ? <h1 className="checkout-title" style={{fontSize:"14px"}}title={'Pasūtīto preču svars pārsniedz ar pakomātu nosūtāmo, pieejama piegāde ar kurjeru'}>Pasūtīto preču kopējais svars: {weight.toFixed(2)} kg., pieejama piegāde ar kurjeru</h1> : 
            <h1 className="checkout-title">Izvēlieties {dpdprice === 0 && omnivaprice === 0 && doorToDoorPrice === 0 ? 'bezmaksas' : null} piegādes veidu:</h1>}
            <div className='checkout-img-container'>
                {dpdprice !== null &&
                <div className={`checkout-img-individual-container ${courier === "DPD" ? "active" : ""}`}>
                    <img onClick={(e) => { setModelIsActive(true); setCourier("DPD"); setPakomataNosaukums(null); setPakomats([]); setDeliveryPrice(dpdprice) }} src='/images/pickup_logo (Small).png' className='chechout-logo' alt='DPD pakomāts' />
                    <p>Pakomāts</p>
                    {dpdprice > 0 && <p>€ {dpdprice}</p>}
                </div>}
                {omnivaprice !== null &&
                <div className={`checkout-img-individual-container ${courier === "Omniva" ? "active" : ""}`}>
                    <img onClick={(e) => { setModelIsActive(true); setCourier("Omniva"); setPakomataNosaukums(null); setPakomats([]); setDeliveryPrice(omnivaprice) }} src='/images/omniva (Small).png' className='chechout-logo' alt='Omniva Pakomāts' />
                    <p>Pakomāts</p>
                    {omnivaprice > 0 && <p>€ {omnivaprice}</p>}
                </div>}
                <div className={`checkout-img-individual-container ${courier === "door-to-door" ? "active" : ""}`}>
                    <img onClick={(e) => { setModelIsActive(true); setCourier("door-to-door"); setPakomataNosaukums(null); setPakomats([]); setDeliveryPrice(doorToDoorPrice) }} src='/images/dpd (Small).png' className='chechout-logo' alt='Piegāde ar DPD kurjeru' />
                    <p>Kurjera piegāde uz adresi</p>
                    {doorToDoorPrice > 0 && <p>€ {doorToDoorPrice}</p>}
                </div>
            </div>
            </>
            )}  
            {isLoading ? null : showPage === 1 && (
            courier === "DPD" || courier === 'Omniva' ? (
                <div className='pakomatuSaraksta-konteiners'>
                    <input
                        type="text"
                        id="myInput"
                        className={`mekletajs active`}
                        placeholder={pakomataNosaukums ? pakomataNosaukums : `Meklēt ${courier} pakomātu`}
                        onChange={handleSearch}
                        onClick={() => modelIsActive === false ? modelActive() : null}
                    />
                    {userSearch.length > 0 ? (
                        userSearch.map((item, index) => (
                            <div
                                value={item.Id}
                                key={index}
                                className={`pakomatuSaraksts ${modelIsActive ? 'active' : ''}`}
                                onClick={(e) => {
                                    setPakomats(e.target.getAttribute('value'));
                                    setPakomataNosaukums(item.Nosaukums);
                                    modelActive();
                                    setUserSearch([]);
                                    document.getElementById('myInput').value = '';
                                }}
                            >
                                {item.Nosaukums}
                            </div>
                        )))
                        :
                        <>
                            {dpdData.map((item) => (
                                <div
                                    value={item.Id}
                                    className={`pakomatuSaraksts ${modelIsActive ? 'active' : ''}`}
                                    onClick={(e) => {
                                        setPakomats(e.target.getAttribute('value'));
                                        modelActive();
                                    }}
                                >
                                    {item.Nosaukums}
                                </div>
                            ))}
                        </>
                    }
                </div>
            ) :
                courier === "door-to-door" ? (

                    isAuthorized ? (
                        <>
                            <div className="seperator"></div>
                            <h2 className="checkout-title">Piegādes adrese</h2>
                            {!city || !street || !postCode || streetNo ? <p className='chechout-addres-notification'>Nomaini piegādes adresi pie sava profila un tā šeit parādīsies automātiski!</p> :
                                <p className='chechout-addres-notification'> Ja vēlaties mainīt piegādes adresi, to varat izdarīt pie sava profila!</p>}
                            <div className="field-group">
                                {city ?
                                    <div>
                                        <p>Pilsēta:</p>
                                        <span>{city}</span>
                                    </div>
                                    :
                                    <div className="input-msg2">
                                        <input type="text" placeholder="Pilsēta" required value={userCity} onChange={(e) => setUserCity(e.target.value)} />
                                    </div>}
                                {street ?
                                    <div>
                                        <p>Adrese:</p>
                                        <span>{street}</span>
                                    </div>
                                    :
                                    <div className="input-msg2">
                                        <input type="text" placeholder="Iela" required value={userStreet} onChange={(e) => setUserStreet(e.target.value)} />
                                    </div>}
                                {streetNo ?
                                    <div>
                                        <p>Mājas Nr.:</p>
                                        <span>{streetNo}</span>
                                    </div>
                                    :
                                    <div className="input-msg2">
                                        <input type="text" placeholder="Mājas Nr." required value={userStreetNo} onChange={(e) => setUserStreetNo(e.target.value)} />
                                    </div>}
                                {flatNo ?
                                    <div>
                                        <p>Dzīvokļa Nr.:</p>
                                        <span>{flatNo}</span>
                                    </div>
                                    : !streetNo && !street && !city && !postCode &&
                                    <div className="input-msg2">
                                        <input type="text" placeholder="Dzīvokļa Nr." value={userFlatNo} onChange={(e) => setUserFlatNo(e.target.value)} />
                                    </div>}
                                {postCode ?
                                    <div>
                                        <p>Pasta indekss:</p>
                                        <span>{postCode}</span>
                                    </div>
                                    :
                                    <div className="input-msg2">
                                        <input type="text" placeholder="Pasta indekss (piem. LV-1005)" required value={userPostCode} onChange={(e) => setUserPostCode(e.target.value)} />
                                    </div>}
                            </div>
                        </>)
                        :
                        <>
                            <div className="seperator"></div>
                            <h2 className="checkout-title">Piegādes adrese</h2>
                            <div className="field-group">
                            <div className="input-msg2">
                                <input type="text" placeholder="Pilsēta" required value={city} onChange={(e) => setCity(e.target.value)} />
                            </div>
                            </div>
                            <div className="field-group">
                                <div className="input-msg2">
                                    <input type="text" placeholder="Iela" required value={street} onChange={(e) => setStreet(e.target.value)} />
                                </div>
                            </div>
                            <div className="field-group">
                                <div className="input-msg2">
                                    <input type="text" placeholder="Mājas Nr." required value={streetNo} onChange={(e) => setStreetNo(e.target.value)} />
                                </div>
                            </div>
                            <div className="field-group">
                                <div className="input-msg2">
                                    <input type="text" placeholder="Dzīvokļa Nr." value={flatNo} onChange={(e) => setFlatNo(e.target.value)} />
                                </div>
                            </div>
                            <div className="field-group">
                                <div className="input-msg2">
                                    <input type="text" placeholder="Pasta indekss" required value={postCode} onChange={(e) => setPostCode(e.target.value)} />
                                </div>
                            </div>
                        </>

                ) : null
            )}
            { isLoading ? null :
            <>
            {pageType === 'options' && showPage === 1 && (
            <>
            <div className="seperator"></div>
            <h1 className="checkout-title">Izvēlieties apmaksas veidu:</h1>
            <div className='checkout-img-container'>                
                <div className={`checkout-img-individual-container ${paymentOption === "Invoice" ? "active" : ""}`}>
                    <img onClick={(e) => {setPaymentOption("Invoice")}} src='/images/logo.jpg' className='chechout-logo-small2' alt='Priekšapmaksas rēķins' />
                    <p>Priekšapmaksas rēķins</p>                    
                </div>                
                <div className={`checkout-img-individual-container ${paymentOption === "Klix" ? "active" : ""}`}>
                    <img onClick={(e) => {setPaymentOption("Klix")}} src='/images/icon-klix_pay_later.png' className='chechout-logo-small' alt='Klix Pay Later' />
                    <p>Dalītais maksājums</p>
                    {omnivaprice > 0 && <p>€ {omnivaprice}</p>}
                </div>
            </div>
            </>
            )}




            {showPage === 1 && (
              <div className='checkbox-container'>
              <input 
                  type="checkbox" 
                  id="terms" 
                  name="terms" 
                  value="terms" 
                  required 
                  onChange={handleTerms} 
                  checked={termsAccept} 
              />
              <label className="checkboxContent" htmlFor="terms">
                  Iepazinos un piekrītu <a href='/terms' target="_blank">lietošanas noteikumiem</a> un <a href='/privacy' target="_blank">privātuma politikai</a>
              </label>
          </div>
            )}
            </>
            }
            {isLoading ? null :  (
            allsubmited && showPage === 1 ? (
                <div className="button-container">
                            <button className="logIn" onClick={(e) => {
                                e.preventDefault(); setShowPage(2); window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth'
                                });
                            }}>Tālāk</button>
                </div>
            ) : showPage === 1 && (
                <div className="button-container">
                    <button className="logIn" style={{ color: "black", backgroundColor: "#e8e8e8" }}  onClick={(e) => {
                                e.preventDefault();showNotification('Lūdzu aizpildiet informāciju!')}}>Tālāk</button>
                </div>
                )
            )}
            
            {isLoading ? null : showPage === 2 && 
                    <div className="Page2">
                        <h2 className="checkout-title">Pasūtītājs</h2>
                        {isCompany && (
                            <>
                                <div className="field-group-page2">
                                    <div>
                                        <p>Uzņēmuma nosaukums:</p>
                                        <span>{companyName}</span>
                                    </div>
                                </div>
                                <div className="field-group-page2">
                                    <div>
                                        <p>Uzņēmuma reģistrācijas Nr.:</p>
                                        <span>{regNumber}</span>
                                    </div>
                                </div>
                                {vatNumber && (
                                    <div className="field-group-page2">
                                        <div>
                                            <p>PVN reģistrācijas Nr.:</p>
                                            <span>{vatNumber}</span>
                                        </div>
                                    </div>
                                )}
                                <div className="field-group-page2">
                                    <div>
                                        <p>Juridiskā adrese:</p>
                                        <span>{legalAddress}</span>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="field-group-page2">
                            <div>
                                <p>Vārds:</p>
                                <span>{name}</span>
                            </div>
                        </div>
                        <div className="field-group-page2">
                            <div>
                                <p>Uzvārds:</p>
                                <span>{surname}</span>
                            </div>
                        </div>
                        <div className="field-group-page2">
                            <div>
                                <p>E-pasts:</p>
                                <span>{email}</span>
                            </div>
                        </div>
                        <div className="field-group-page2">
                            <div>
                                <p>Kontakttālrunis:</p>
                                <span>{phone}</span>
                            </div>
                        </div>
                        <h2 className="checkout-title">Piegāde</h2>
                        <div className="field-group-page2">
                            <div>
                                <p>Piegādes veids:</p>
                                <span>{courier !== "door-to-door" ? `${courier} pakomāts` : "Kurjera piegāde"}</span>
                            </div>
                        </div>
                        {courier !== "door-to-door" && (
                            <div className="field-group-page2">
                                <div>
                                    <p>Pakomāts:</p>
                                    <div className='longTextCheckout'>{pakomataNosaukums}</div>
                                </div>
                            </div>
                        )}
                        {courier === "door-to-door" && (
                            <div className="field-group-page2">
                                <div>
                                    <p>Piegādes adrese:</p>
                                    <div className='longTextCheckout'>{city}, {street} {streetNo}, {flatNo && `dz. ${flatNo},`} {postCode}</div>
                                </div>
                            </div>
                        )}
                        {deliveryPrice > 0 && <div className="field-group-page2">
                            <div>
                                <p>Piegādes maksa:</p>
                                <span>€{deliveryPrice}</span>
                            </div>
                        </div>}
                        <h2 className="checkout-title">Preces</h2>
                        <div className="cart-container" style={{ marginBottom: "10px" }}>
                            {cartToShow.map((product) => (
                                <div className="cart-item" key={product.SKU}>
                                    <div className="product-image-container">
                                        <img src={
                                            product.MENU_2 === "Officeshop ražotie kārtridži" ? '/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg' :
                                                product.MENU_1 === "Kopējošās lentas (ribbon)" ? '/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg' :
                                                    product.PICTURE ? product.PICTURE.startsWith('http') ? product.PICTURE : `/prodImages/Small/${product.SKU} (Small)/${product.SKU} (Small).${product.PICTURE}` :
                                                        `/prodImages/Small/logo (Small)/logo (Small).png`} alt={product.NAME} className="image" />

                                    </div>
                                    <p className="name">
                                        <Link to={
                                            product.MENU_2 === 'N/A' ? `/categories/${product.SKU}` :
                                                product.MENU_3 === 'N/A' ? `/categories/${encodeURIComponent(product.MENU_1).replace(/%20/g, '-')}/${encodeURIComponent(product.MENU_2).replace(/%20/g, '-')}/${product.SKU}` :
                                                    `/categories/${encodeURIComponent(product.MENU_1).replace(/%20/g, '-')}/${encodeURIComponent(product.MENU_2).replace(/%20/g, '-')}/${encodeURIComponent(product.MENU_3).replace(/%20/g, '-')}/${product.SKU}`
                                        }>
                                            {product.NAME}
                                        </Link>
                                    </p>
                                    <p className="cena">{cartItems.find(cartItem => cartItem.sku === product.SKU)?.quantity}</p>

                                    <p className="cena">€{product.PRICE}</p>
                                </div>
                            ))}
                        </div>
                        {smallOrderFee > 0 && <div className="field-group-page2">
                            <div>
                                <p>Maza pasūtījuma apkalpošana:</p>
                                <span>€{smallOrderFee}</span>
                            </div>
                        </div>}
                        <div className="field-group-page2" style={{ marginTop: "10px" }}>
                            <div>
                                <p>Kopā apmaksai:</p>
                                <span style={{ fontWeight: "bold"}}>€{(total + smallOrderFee + deliveryPrice).toFixed(2)}</span>
                            </div>
                        </div>
                        <p className='adjustInfoText' onClick={(e) => { e.preventDefault(); setShowPage(1); }}>Labot informāciju</p>   
                        <div className="button-container">
                            
                            <button className="logIn" onClick={handleCheckout}>Pasūtīt</button>
                        </div>
                    </div>
        }

        </div>
        </>
    );
};

export default Checkout;



