import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ProductCard from '../components/productCard/ProductCard.js';
import ProductPage from './product.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Pagination } from 'react-bootstrap/';
import '..//components/ProductDisplay/categories.css';
import useFirebase from "../firebase/usefirebase";
import { onAuthStateChanged } from "firebase/auth";
import { Helmet } from 'react-helmet';
import Mobile from '../components/Reklama/Sides/Mobile.js';
import './sale.css';





const Sale = () => { 

  const [menuItems, setMenuItems] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [sort, setSort] = useState('');
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [email, setEmail] = useState("");
  const [showInStock, setShowInStock] = useState(false);  
  const [specImage, setSpecImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const [productsPerPage, setProductsPerPage] = useState(15);
  const [productKeywords, setProductKeywords] = useState(false);
  const [categoryKeywords, setCategoryKeywords] = useState(false);
  const [showMobileAdd, setShowMobileAdd] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const currentPage = parseInt(query.get('page') || '1', 10);
  const totalPages = Math.ceil(selectedProducts.length / productsPerPage);
  const maxPagesToShow = 6;
  const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER;

  const { auth, user } = useFirebase();
  useEffect(() => {
    const updateProductsPerPage = () => {
      if (window.innerWidth < 768) {
        setProductsPerPage(16);
        setShowMobileAdd(true);
      } else {
        setProductsPerPage(15);
        setShowMobileAdd(false);
      }
    };
    updateProductsPerPage();
    window.addEventListener('resize', updateProductsPerPage);
    window.scrollTo(0, 0);
    if (auth) {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setEmail(user.email);
          fetch(`${REACT_APP_API_SERVER}/favorites?Email=${user.email}`)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Failed to fetch user data");
              }
              return response.json();
            })
            .then((data) => {
              const favoritesString = data[0]?.Favorites || '';
              const favoritesArray = favoritesString.split(",").filter(item => item.trim() !== '');
              setFavorites(favoritesArray);
            })
            .catch((error) => console.error(error))
            .finally(() => setAuthLoading(false));
        } else {
          setAuthLoading(false);
        }
      });

      return () => {
        window.removeEventListener('resize', updateProductsPerPage);
        unsubscribe();
      };
    } else {
      setAuthLoading(false);
    }
  }, [REACT_APP_API_SERVER, auth]);

  

  useEffect(() => {
    if (authLoading) return;
    let timerId = setTimeout(() => {
      setIsLoading(true);
    }, 500);
    fetch(`${REACT_APP_API_SERVER}/sale`)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Failed to fetch user data");
              }
              return response.json();
            })
            .then((data) => {              
              clearTimeout(timerId);
              let filteredProducts = data;   
              setIsLoading(false);
          setSelectedProducts(filteredProducts);
          setAllProducts(filteredProducts);
          setBrands([...new Set(data.map(item => item.BRAND))]);
            })
      .catch((error) => {
        console.error(error);
      });
  }, [authLoading]);

  useEffect(() => {
    let filteredProducts = allProducts;
          if (showInStock === true) {
            filteredProducts = filteredProducts.filter(product => product.QTY > 0);
          }
          if (selectedBrands.length > 0) {
            filteredProducts = filteredProducts.filter(product => selectedBrands.includes(product.BRAND));
          }
          if (sort) {
            filteredProducts = sortProductsByPrice(filteredProducts, sort);
          }
          setSelectedProducts(filteredProducts);
  }, [showInStock, selectedBrands, sort]);

  const updateFavoritesAfterDelete = () => {
    fetch(`${REACT_APP_API_SERVER}/favorites?Email=${email}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        return response.json();
      })
      .then((data) => {
        const favoritesString = data[0].Favorites;
        const favoritesArray = favoritesString.split(",");
        setFavorites(favoritesArray);
      })
      .catch((error) => console.error(error));
  };


  const sortProductsByPrice = (products, order) => {
    const sortedProducts = [...products].sort((a, b) => {
      const priceA = parseFloat(a.PRICE);
      const priceB = parseFloat(b.PRICE);
      if (order === 'asc') {
        return priceA - priceB;
      } else if (order === 'desc') {
        return priceB - priceA;
      } else {
        return products;
      }
    });
    return sortedProducts;
  };


  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleInStockChange = (event) => {
    setShowInStock(event.target.checked);
    paginate(1);
  };

  const handleBrandSelection = (event) => {
    const { value } = event.target;
    const brandIndex = selectedBrands.indexOf(value);
    if (brandIndex === -1) {      
      setSelectedBrands([...selectedBrands, value]);      
    } else {
      const updatedBrands = [...selectedBrands];
      updatedBrands.splice(brandIndex, 1);      
      setSelectedBrands(updatedBrands);     
    }
    paginate(1);
  };






  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = selectedProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    query.set('page', pageNumber);
    navigate({ search: query.toString() });
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!query.get('page') && selectedProducts.length > 0) {
      paginate(1);
    }
  }, []);

  let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
  let endPage = startPage + maxPagesToShow - 1;

  const renderPaginationItems = () => {
    const items = [];
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = startPage + maxPagesToShow - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    for (let page = startPage; page <= endPage; page++) {
      items.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => paginate(page)}
        >
          {page}
        </Pagination.Item>
      );
    }

    return items;
  };

  //Izlasam pirmos četru vārdus no produkta nosaukuma
  function shortenKeyword(productName) {
    const words = productName.split(' ');
    const keywordArray = words.slice(0, 4);
    const keyword = keywordArray.join(' ');
    return keyword;
  }



  useEffect(() => {
    if (currentProducts.length > 0) {
      const keywords = currentProducts
        .filter(product => product.NAME !== null)
        .map(product => shortenKeyword(product.NAME))
        .join(', ');
      setProductKeywords(keywords);
    }
  }, [currentProducts]);

  

  const getNextDayDate = () => {
    const now = new Date();
    const nextDay = new Date(now);
    nextDay.setDate(now.getDate() + 1); 
    nextDay.setHours(23, 59, 0); 
    return nextDay.toISOString().split('T')[0] + 'T23:59:00'; 
  };

  let jsonLD;
  if (selectedProducts.length > 0) {
    jsonLD = {
      "@context": "https://schema.org",
      "@type": "ItemList",
      "name": `Izpārdošana - OfficeShop.lv`,
      "itemListElement":
        selectedProducts.map((product, index) => ({        
        "@type": "Product",
        "name": product.NAME,
        "image": product.MENU_2 === "Officeshop ražotie kārtridži" ? `https://www.officeshop.lv/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg` :
          product.MENU_1 === "Kopējošās lentas (ribbon)" ? `https://www.officeshop.lv/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg` :
            product.PICTURE && product.PICTURE.startsWith('http') ? product.PICTURE : product.PICTURE ? `https://www.officeshop.lv/prodImages/Small/${product.SKU} (Small)/${product.SKU} (Small).${product.PICTURE}` : `https://www.officeshop.lv/prodImages/Small/logo (Small)/logo (Small).png`,
        "description": product.NAME + '. Pasūti tagad! Ātra piegāde un iepriecinošas cenas. Izvēlies no vairāk kā 100`000 precēm! www.OfficeShop.lv',
        "brand": product.BRAND !== null ? product.BRAND : "OfficeShop",
        "offers": {
          "@type": "Offer",
          "price": product.PRICE,
          "priceCurrency": "EUR",
          'priceValidUntil': getNextDayDate(),
          "availability": product.QTY > 0 ? "InStock" : "OutOfStock",
          "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "returnPolicyCategory": "https://schema.org/ReturnAllowed",
            "applicableCountry": "LV",
            "returnPolicySeasonalOverride": "false",
            "merchantReturnDays": 14
          },
          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingDestination": {
              "@type": "DefinedRegion",
              "name": "Latvia"
            },
            "deliveryTime": {
              "@type": "ShippingDeliveryTime",
              "businessDays": 3
            }
          }
        },
        "url": "https://www.officeshop.lv" + "/categories" +  + product.SKU,      
      }))
      
    };
  } 
  // let Filter = [];


  // const Filter1 = [...new Set(selectedProducts.map(product => product.ATTRIBUTE_NAME1))];
  // const Filter2 = [...new Set(selectedProducts.map(product => product.ATTRIBUTE_NAME2))];
  // const Filter3 = [...new Set(selectedProducts.map(product => product.ATTRIBUTE_NAME3))];
  // const Filter4 = [...new Set(selectedProducts.map(product => product.ATTRIBUTE_NAME4))];
  // const Filter5 = [...new Set(selectedProducts.map(product => product.ATTRIBUTE_NAME5))];

  // const combinedSet = new Set([...Filter1, ...Filter2, ...Filter3, ...Filter4, ...Filter5]);

  // Filter = [...combinedSet];

  return (
    <>
      <Helmet>
        <title>{`Izpārdošana - OfficeShop.lv`}</title>
        <meta name="description" content={`Izpārdošana, atlaides līdz 35%, atrodi sev nepieciešamo | OfficeShop.lv`} />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={productKeywords} />
        <meta property="og:title" content={`Izpārdošana - OfficeShop.lv`} />
        <meta property="og:description" content={`Izpārdošana, atlaides līdz 35%, atrodi sev nepieciešamo | OfficeShop.lv`} />
        <meta property="og:url" content={`https://www.officeshop.lv/sale`} />
        <link rel="canonical" href={`https://www.officeshop.lv/sale`} />
        <meta property="og:image" content={`https://www.officeshop.lv/prodImages/logo.png`} />

        <meta property="og:image:alt" content={`Izpārdošana, atlaides līdz 35%, atrodi sev nepieciešamo | OfficeShop.lv`} />
        <meta property="og:type" content="website" />
        {jsonLD && <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>}
      </Helmet>
      {!isLoading && showMobileAdd && !selectedItem.length > 0 && (menuItems.length > 0 || selectedProducts.length > 0)  ? <Mobile /> : null}      
      {isLoading && (menuItems.length < 0 || selectedProducts.length < 0 ) ? <div className='product-page'>
        <img src='/images/loading.gif' alt='loading' />
      </div> : 
      selectedProducts.length > 0  && !isLoading ? (
        <>
        <div>
          <img src='./images//Sale.jpg' className='SaleBanner'/>
        </div>
            {/* <div className='sale-container'>
              <div className='sale-left'>
                <div className='left-card-top'>
                  <img className='sale-big-image' src='/catImages/Kafijas automāti.jpg' alt='sale1' />
                  <p className='fixedText'>no €299</p>
                </div>
                <div className='left-card-bottom'>
                  <h2>WOW cenas elektronikai!</h2>
                </div>
              </div>
              <div className='sale-right'>
                <div className='right-row'>
                  <div className='row-card'>
                    <img className='sale-big-image' src='/catImages/Kafijas automāti.jpg' alt='sale1' />
                    <p className='fixedText'>no €299</p>
                  </div>
                  <div className='row-card'>
                    <img className='sale-big-image' src='/catImages/Kafijas automāti.jpg' alt='sale1' />
                    <p className='fixedText'>no €299</p>
                  </div>
                </div>
                <div className='right-row'>
                <img className='sale-right-image' src='/catImages/Kafijas automāti.jpg' alt='sale1' />
                <div className='sale-right-textContainer'>
                <p clasName='Sale-category-name'>Kafijas automāti</p>
                <p className='Sale-price'>no €799</p>
                </div>
                </div>
                <div className='right-row'>
                  <div className='row-card'>
                    <img className='sale-big-image' src='/catImages/Kafijas automāti.jpg' alt='sale1' />
                    <p className='fixedText'>no €299</p>
                  </div>
                  <div className='row-card'>
                    <img className='sale-big-image' src='/catImages/Kafijas automāti.jpg' alt='sale1' />
                    <p className='fixedText'>no €299</p>
                  </div>
                </div>
                <div className='right-row'>
                  <div className='row-card'>
                    <img className='sale-big-image' src='/catImages/Kafijas automāti.jpg' alt='sale1' />
                    <p className='fixedText'>no €299</p>
                  </div>
                  <div className='row-card'>
                    <img className='sale-big-image' src='/catImages/Kafijas automāti.jpg' alt='sale1' />
                    <p className='fixedText'>no €299</p>
                  </div>
                </div>
              </div>
            </div> */}
          <div className='above-sorting'>
            <div className='sortingContainerShow'>
              <h6 className='sortingTitle'>Cena</h6>
              <select onChange={handleSortChange}>
                <option value=''>↑ ↓</option>
                <option value='desc'>Cena ↑</option>
                <option value='asc'>Cena ↓</option>
              </select>
            </div>

            <div className='sorting'>
              <h6 className='sortingTitle'>Zīmoli</h6>
              <div className='sorting-container'>
                <div className='section'>
                  <div className='brands'>
                    {brands.sort((a, b) => a.localeCompare(b)).map((brand, index) => (
                      <div key={index}>
                        <input type='checkbox' id={brand} name={brand} value={brand} onChange={handleBrandSelection} />
                        <label htmlFor={brand}>{brand}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className='sortingContainerShow'>
              <h6 className='sortingTitle'>Preces pieejamība</h6>
              <input className='stock' type='checkbox' id='inStock' name='inStock' value='inStock' onChange={handleInStockChange} />
              <label htmlFor='inStock'></label>
            </div>
          </div>
          <div className="product-page">
            {currentProducts.map((item, index) => (              
              <div key={index} className="product">
                <ProductCard
                  name={item.NAME}
                  price={item.PRICE}
                  quantity={item.QTY}                  
                  minorder={item.MINORDER}
                  discount={item.DISCOUNT}
                  link={
                    item.MENU_3 === 'N/A'
                        ? `/categories/${encodeURIComponent(item.MENU_1).replace(
                              /%20/g,
                              '-'
                          )}/${encodeURIComponent(item.MENU_2).replace(
                              /%20/g,
                              '-'
                          )}/${item.SKU}`
                        : `/categories/${encodeURIComponent(item.MENU_1).replace(
                              /%20/g,
                              '-'
                          )}/${encodeURIComponent(item.MENU_2).replace(
                              /%20/g,
                              '-'
                          )}/${encodeURIComponent(item.MENU_3).replace(
                              /%20/g,
                              '-'
                          )}/${item.SKU}`
                }
                image={item.MENU_2 === "Officeshop ražotie kārtridži"?  '/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg':
                  item.MENU_1 === "Kopējošās lentas (ribbon)" ? '/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg':
                  item.PICTURE ? item.PICTURE.startsWith('http') ? item.PICTURE : `/prodImages/Small/${item.SKU} (Small)/${item.SKU} (Small).${item.PICTURE}`:
                  `/prodImages/Small/logo (Small)/logo (Small).png`}
                  sku={item.SKU}
                  fav={favorites.includes(item.SKU) ? 'yes' : 'no'}
                  onDeleteFav={() => { updateFavoritesAfterDelete(); }}
                  userEmail={email}
                />
              </div>
            ))}
          </div>
          {selectedProducts.length > productsPerPage ? (
            <Pagination className='custom-pagination'>
              <Pagination.First onClick={() => currentPage === 1 ? null : paginate(1)} />
              <Pagination.Prev onClick={() => currentPage === 1 ? null : paginate(currentPage - 1)} />
              {startPage > 1 && <Pagination.Ellipsis onClick={() => paginate(startPage - 1)} />}
              {renderPaginationItems()}
              {endPage < totalPages && <Pagination.Ellipsis onClick={() => paginate(endPage + 1)} />}
              <Pagination.Next onClick={() => currentPage === totalPages ? null : paginate(currentPage + 1)} />
              <Pagination.Last onClick={() => currentPage === totalPages ? null : paginate(totalPages)} />
            </Pagination>
          ) : null}
        </>
      ) : selectedItem.length > 0 && ! isLoading ? (
        <div>
          <ProductPage item={selectedItem} />
        </div>
      ) :
        <div className='product-page'>
        </div>
      }
    </>
  );
};

export default Sale;
